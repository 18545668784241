import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
} from "react-router-dom";
import Home from "./layout/Home";
import {
  Container,
  HStack,
  Text,
  StackDivider,
  Grid,
  GridItem,
  Link as ChakraLink,
  Flex,
} from "@chakra-ui/react";
import Jobs from "./layout/Jobs";

function App() {
  return (
    <Router>
      <Container
        marginTop={5}
        padding={5}
        backgroundColor="cardBg"
        boxShadow="md"
      >
        <div>
          <nav>
            <Grid templateColumns="repeat(5, 1fr)" gap={4}>
              <GridItem colSpan={2} h="10">
                <ChakraLink as={RouterLink} to="/">
                  <Text fontSize="2xl" justifyContent="end">
                    D16
                  </Text>
                </ChakraLink>
              </GridItem>
              <GridItem colStart={4} colEnd={6} h="10">
                <HStack
                  divider={<StackDivider borderColor="tertiary" />}
                  justifyContent="right"
                  alignContent="center"
                >
                  <ChakraLink as={RouterLink} to="/jobs">
                    Jobs
                  </ChakraLink>
                  <ChakraLink href="https://discord.gg/d9SHSGAc" isExternal>
                    Discord
                  </ChakraLink>
                </HStack>
              </GridItem>
            </Grid>
          </nav>

          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/jobs">
              <Jobs />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </div>
        <Flex justifyContent="center">&copy; 2021 D16, LLC</Flex>
      </Container>
    </Router>
  );
}

export default App;
