import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Text,
  Link as ChakraLink,
  Box,
  Heading,
  Code,
  Wrap,
} from "@chakra-ui/react";

const Feature = (props: { title: string; desc: string }) => {
  const { title, desc } = props;
  return (
    <Box
      minW="200"
      p={5}
      shadow="md"
      borderWidth="1px"
      flex="1"
      borderRadius="md"
    >
      <Heading fontSize="xl">{title}</Heading>
      <Text mt={4}>{desc}</Text>
    </Box>
  );
};

const Home = () => {
  return (
    <Box>
      <Heading fontSize="4xl" color="headline">
        Hello! 👋
      </Heading>
      <Text paddingBottom="4" color="paragraph">
        We're D16, a new technology company that believes that technology and
        art go hand in hand. That's why we're taking a different approach to
        building products:{" "}
        <Text paddingBottom="4" as="span" fontStyle="italic">
          Most people won't be interested in our products.
        </Text>
      </Text>
      <Text paddingBottom="4">
        Don't get us wrong, we're building great products and you won't be
        offended by anything we build. However, they might not be something
        you're interested in. That's ok!
      </Text>
      <Text paddingBottom="4">
        Too many tech companies today are driven around maximizing engagement
        and capturing as much market share as possible as fast as possible. Apps
        and platforms that are designed to be as sticky as possible would rather
        consume more of your time than provide you a more useful service.
      </Text>
      <Text paddingBottom="4">
        In a landscape of infinite scroll, recommendation algorithms, and
        platforms with dozens of features bolted on every week, we started to
        get a bit tired.
      </Text>
      <Text paddingBottom="4">
        Instead, we aim to build products that do one or two jobs really well
        and then get out of your way. Rather than trying to consume as much of
        your time and be all things to all users. Our products would rather
        understand their task really well and play nicely with other tools.
      </Text>
      <Heading fontSize="2xl" as="h2" color="headline">
        What that doesn't mean...
      </Heading>
      <Text paddingBottom="4">
        That doesn't mean our products should be inaccessible to anyone, rather
        the opposite. We aim make all of our products accessible to people of
        different abilities and technical competencies.
      </Text>
      <Heading fontSize="2xl" as="h2" color="headline">
        The secret sauce
      </Heading>
      <Text paddingBottom="4">
        We're going to let you in on what we think our secret sauce is going to
        be: we're going to keep parity between artists and engineers.
      </Text>
      <Text paddingBottom="4">
        There's a certain type of magic that we've seen happen when there's an
        even number of artists to engineers. Something fundamentally changes
        about a team's chemistry. Everything becomes beautiful, empathizing with
        customers becomes easier, and using products becomes effortless.
      </Text>
      <Heading fontSize="2xl" as="h2" color="headline">
        So what's first?
      </Heading>
      <Text paddingBottom="4">
        We have two great things on the burner that we think someone will love
        to use... because we'll be using them ourselves...
      </Text>
      <Wrap spacing={8} padding="4" paddingBottom="8">
        <Feature
          title="CIDR"
          desc="Make network allocation and accounting easier from the command line, API, or Terraform. Network admins, throw away your Excel spreadsheets!"
        />
        <Feature
          title="Notion Pruner"
          desc="Every week you'll get an email with the oldest pages in your team's Notion workspace so you can keep pages from rotting and keep your precious resource full of useful data."
        />
      </Wrap>
      <Heading fontSize="2xl" as="h2" color="headline">
        Get in touch?
      </Heading>
      <Text paddingBottom="4">
        We think D16 will be a tremendous experiment. Are you interested in
        joining us or trying a product early? Hop over to{" "}
        <ChakraLink as={RouterLink} to="/jobs">
          our jobs page
        </ChakraLink>{" "}
        to view our openings or email us at{" "}
        <Code>
          <ChakraLink isExternal href="mailto:hello@d16.dev?Subject=Hello D16">
            hello@d16.dev
          </ChakraLink>
        </Code>{" "}
        to get in touch.
      </Text>
    </Box>
  );
};
export default Home;
