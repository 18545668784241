import { ReactFragment } from "react";
import {
  Text,
  Box,
  Heading,
  ListItem,
  UnorderedList,
  Center,
  Code,
  Link as ChakraLink,
} from "@chakra-ui/react";

const Role = (props: { title: string; children: ReactFragment }) => {
  const { title, children } = props;
  return (
    <Box p={5} shadow="sm" borderWidth="1px" flex="1" borderRadius="md">
      <Heading fontSize="xl" color="headline">
        {title}
      </Heading>
      <Text pb="2">
        Location:{" "}
        <Text fontWeight="700" as="span">
          Remote
        </Text>
      </Text>
      {children}
      <Center>
        <Text fontStyle="italic">
          Ready to learn more? Email us at{" "}
          <Code>
            <ChakraLink
              isExternal
              href={`mailto:hello@d16.dev?Subject=${title}`}
            >
              hello@d16.dev
            </ChakraLink>
          </Code>
        </Text>
      </Center>
    </Box>
  );
};

const Jobs = () => {
  return (
    <Box>
      <Heading fontSize="4xl" color="headline">
        Jobs
      </Heading>
      <Text paddingBottom="4" color="paragraph">
        We're looking for talented people from diverse backgrounds to join our
        team in building a variety of projects with accessibility and design as
        primary concerns.
      </Text>
      <Text pb="4">
        We're hoping to have as diverse a team as we can so D16 is a
        remote-first company and welcomes applicants from all around the world.
      </Text>
      <Heading fontSize="2xl" as="h2" color="headline">
        D16 is an Equal Opportunity Employer
      </Heading>
      <Text pb="4">
        No candidate is perfectly qualified for any role but a lot of skills and
        experience are transferrable. We expect every member of the team to
        demonstrate accountability, innovation, and the ability to work in a
        dynamic and high-collaboration environment. We expect you to try new
        things in these roles and we encourage you to apply if you're close to
        what is listed below.
      </Text>
      <Text pb="4">
        Just like our products, none of us are frozen in time. Growing as team
        members involves learning new tools, recognizing our weaknesses, and
        building our strengths. As a group we'll be here to educate and assist
        each other as we grow in new ways.
      </Text>
      <Heading fontSize="2xl" as="h2" color="headline">
        Roles
      </Heading>
      <Role title="Designer">
        <Text pb="4">
          D16 is looking for graphic designers, product designers, and visual
          artists to build products that people love to use. Ranging from
          developer tools to new CAPTCHA technologies our products should be a
          joy to work with but easily stay out of the way of users. Join us and
          help design the perfect user experience.
        </Text>
        <Text pb="4">
          In this role you can expect to:
          <UnorderedList>
            <ListItem>Design web applications using tools like Figma</ListItem>
            <ListItem>
              Work directly with developers to design and verify the
              implementation of applications
            </ListItem>
            <ListItem>
              Develop or select design systems for use in applications
            </ListItem>
            <ListItem>
              Work with other designers and product designers to collect user
              feedback
            </ListItem>
            <ListItem>
              Participate in ideation, design, and selection of new product
              opportunities
            </ListItem>
          </UnorderedList>
        </Text>
      </Role>
      <Role title="Frontend Engineer or Backend Engineer">
        <Text pb="4">
          Frontend or backend engineer looking to work closer with artists?
          We're looking for both frontend and backend engineers that want to
          work on well designed products with specific focus on customers'
          needs. We aim to use a unified tool set across all of our products and
          focus on maintainability over feature velocity. Join us and help build
          technologies that are exactly what the customer needed.
        </Text>
        <Text pb="4">
          In this role you can expect to:
          <UnorderedList>
            <ListItem>
              Build and maintain web apps using technologies like TypeScript and
              React
            </ListItem>
            <ListItem>
              Work directly with designers to make enjoyable user interactions
            </ListItem>
            <ListItem>
              Design maintainable systems and architectures that are easy to
              maintain so we can keep building products
            </ListItem>
          </UnorderedList>
        </Text>
      </Role>
      <Role title="DevOps Engineer">
        <Text pb="4">
          D16 products are built for the rapid-deployment, highly available, IaC
          world. Our team won't be complete without your DevOps skills to
          deliver the finely crafted applications via robust infrastructure.
          Join us and help us ensure that our products are always there to serve
          our customers.
        </Text>
        <Text pb="4">
          In this role you can expect to:
          <UnorderedList>
            <ListItem>
              Build and maintain compute platforms using technologies like
              Kubernetes
            </ListItem>
            <ListItem>
              Work with developers to deploy and monitor applications
            </ListItem>
            <ListItem>
              Work with designers to understand customers needs around
              performance and availability
            </ListItem>
          </UnorderedList>
        </Text>
      </Role>
      <Role title="Community Coordinator">
        <Text pb="4">
          CIDR, Notion Rot Watcher, Blogdex, IPFSAuth, and Teletype can't exist
          in a vacuum we need your help to ensure that we're hearing from our
          customers. While we'll be aiming for our products to be as smooth as
          possible people will always need to hear about updates and we'll want
          to hear about their feed back. Join us and help us build better
          products for our customers.
        </Text>
        <Text pb="4">
          In this role you can expect to:
          <UnorderedList>
            <ListItem>
              Manage D16s customer outreach through social media platforms and
              inbound through support channels
            </ListItem>
            <ListItem>
              Work with design and engineering team members to communicate
              customers' needs and pitfalls
            </ListItem>
            <ListItem>
              Observe how customers use our products through surveys and
              interviews
            </ListItem>
          </UnorderedList>
        </Text>
      </Role>
    </Box>
  );
};
export default Jobs;
